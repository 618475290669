import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/404.vue";
import portfolio from "../views/portfolio.vue";
Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  
   {
    path: "/portfolio",
     name: "portfolio",
     component: portfolio
  },

  { path: '*', component: NotFound },
  { path: '*', redirect: '/404' },  


];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }; 
},
});
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/404');
  } else {
    next();
  }
});


export default router;

