<i18n>
    {
        "ar": {
    
            "sections": {
                "1": {
                    "title": " أهم أعمال شركة تصميم مواقع الانترنت نيزك سوفت",
                    "subtitle": "كل الخدمات التي تحتاجونها في مكان واحد وبأيد أمينة"
                },
                "about": {
                    "title": "معلومات عن الشركة",
                    "subtitle": "معلومات وتفاصيل عن الشركة وآلية عملها",
                    "row1": {
                        "title": "من نحن؟ وماذا نفعل؟",
                        "content": "شركة تصميم مواقع الانترنت نيزك سوفت تقدم لك خدمات محملة بخلاصة سنوات من الخبرة والعمل الجاد، فنحن نعرف متطلبات نشاطك التجاري ونعرف كيف نجعل عملاءنا في الصدارة من خلال خطة عمل متكاملة، بداية بتصميم الهوية البصرية مروراً بتصميم موقع الكتروني مميز وانتهاءً بإعداد خطة تسويقية تضمن لهم زيادة عدد المهتمين بنشاطهم التجاري وزيادة في نسب مبيعاتهم وأرباحهم. نحن في شركة تصميم مواقع الانترنت نيزك سوفت نعمل على تصميم مواقع الويب وفق أحدث المعايير العالمية لتكون متوافقة مع كافة الأجهزة الذكية وصديقة لمحركات البحث المختلفة."
                    },
                    "row2": {
                        "title": "تصميم مواقع الكترونية تتسم بالسرعة والتوافق مع الجوال والأجهزة الذكية",
                        "content": "كافة تصاميم واعمال شركة تصميم مواقع الانترنت نيزك سوفت تتسم بالتوافق مع جميع الأجهزة الذكية، هذه الميزة توفر تجربة رائعة لعملائك، ويساعدك على تحقيق أهدافك، والحصول على نتائج مميزة لنشاطك التجاري. السرعة و تناسق الالوان من أهم المعايير التي نأخذها بعين الاعتبار عند انشاء موقع ويب أو تطبيق جوال أو هوية بصرية أو فيديو موشن جرافيك لعملائنا."
                    }
                },
                "contact": {
                    "subtitle": "اترك لنا رسالة وسوف نقوم بالرد عليك بأسرع وقت ممكن"
                },
               
                 "timeline": {
                    "title": "الخط الزمني للشركة",
                    "title1": "معرض أعمالنا",
                    "subtitle": "شريط زمني يضم الأحداث والتواريخ الهامة في تاريخ شركة نيزك سوفت"
                },
                "products": {
                    "title":  "مشاريعنا",
                    "subtitle":"المنصات والخدمات اﻹلكترونية التابعة لشركتنا والتي نعمل على تطويرها وإدراتها"
                },
                "works": {
                    "title": "معرض أعمالنا",
                    "subtitle":"المنصات والخدمات اﻹلكترونية التابعة لشركتنا والتي نعمل على تطويرها وإدراتها"
                },
                "industries": {
                    "title": "خدماتنا",
                    "subtitle":"إبدء نشاطك التجاري علي الأنترنت وإنتقل لمستوي أخر من النجاح والأحترافيه وتميز عن منافسيك.",
                    "cols": [
                        {
                            "headline": "تصميم مواقع الانترنت",
                            "description": "التوافق مع الجوال والسرعة في التصفح أهم ما يميز خدمة تصميم مواقع الانترنت المقدمة من قبل شركة نيزك سوفت، مواقعنا قابلة للتطوير المستمر و تتميز بمعايير تضمن لها الحماية من الاختراق",
                            "as": "hjkjkj"
                        },
                        {
                            "headline": "استضافة مواقع الانترنت",
                            "description": "استضافة مواقع الانترنت استضافة مميزة بامكانيات ضخمة، استقرار عالي في الأداء عبر سيرفرات سريعة وأنظمة حماية قوية تساعد موقعك على الصعود في نتائج البحث في جوجل بقوة أكبر وبزمن قياسي،"
                        },
                        {
                            "headline": "التسويق الكتروني",
                            "description": "خطط تسويق الكترونية مناسبة للجميع - حملات اعلانية ممولة انستغرام - فيس بوك - جوجل، بالاضافة الى خدمات ادارة المحتوى وتهيئة المواقع لمحركات البحث عبر حملات SEO"
                        }
                    ]
                },
                "actions": {
                    "contact": "تواصل معنا",
                    "about": "معرفة المزيد عنّا",
                    "products": "مشاريعنا"
                }
            },
            "projects": {
                    "mosaweqen": { 
                        "title":"منصة إحصائيات نيزك",
                        "subtitle":" نيزك أناليتكس هي عبارة عن منصة تركز على الخصوصية وتحليلات الويب وتوفر تقارير مفصلة عن زوار الويب ، مثل الوقت الفعلي والنظرة العامة والسلوك والموقع الجغرافي والتكنولوجيا والأحداث والمزيد."
                        },
                        "mabi3aat": {
                            "title":"منصة نيزك رانك",
                            "subtitle":"هي منصة أدوات وتقارير SEO شاملة ، تتيح لك إنشاء تقارير SEO سهلة الفهم والتي ستساعد موقع الويب الخاص بك على الظهور في الصفحة الأولى وتحقيق أداء أفضل."
                            },
                            "project3": { 
                                "title":"منصة نيزك لتقصير الروابط",
                                "subtitle":"منصة نيزك لتقصير الروابط هي عبارة عن نظام أساسي لتقصير الروابط يتيح لك تقصير الروابط بسهولة واستهداف جمهورك بناءً على موقعهم أو نظامهم الأساسي ، وتقديم رؤى تحليلية للروابط المختصرة."
                                }
                                
                                },

                                "work": {
                    "mosaweqen": { 
                        "title":"تصميم موقع MyLebanon Guide",
                        "subtitle":" نيزك أناليتكس هي عبارة عن منصة تركز على الخصوصية وتحليلات الويب وتوفر تقارير مفصلة عن زوار الويب ، مثل الوقت الفعلي والنظرة العامة والسلوك والموقع الجغرافي والتكنولوجيا والأحداث والمزيد."
                        },
                        "mabi3aat": {
                            "title":"تصميم موقع infinity cars",
                            "subtitle":"هي منصة أدوات وتقارير SEO شاملة ، تتيح لك إنشاء تقارير SEO سهلة الفهم والتي ستساعد موقع الويب الخاص بك على الظهور في الصفحة الأولى وتحقيق أداء أفضل."
                            },
                            "project3": { 
                                "title":"تصميم موقع Donna Beauty Lounge & Spa",
                                "subtitle":"منصة نيزك لتقصير الروابط هي عبارة عن نظام أساسي لتقصير الروابط يتيح لك تقصير الروابط بسهولة واستهداف جمهورك بناءً على موقعهم أو نظامهم الأساسي ، وتقديم رؤى تحليلية للروابط المختصرة."
                                },
                                "mosaweqen1": { 
                        "title":"تصميم موقع شبكة ZNN الاخبارية",
                        "subtitle":" نيزك أناليتكس هي عبارة عن منصة تركز على الخصوصية وتحليلات الويب وتوفر تقارير مفصلة عن زوار الويب ، مثل الوقت الفعلي والنظرة العامة والسلوك والموقع الجغرافي والتكنولوجيا والأحداث والمزيد."
                        },
                        "mabi3aat1": {
                            "title":"تصميم موقع لشركة فرسان التميز",
                            "subtitle":"هي منصة أدوات وتقارير SEO شاملة ، تتيح لك إنشاء تقارير SEO سهلة الفهم والتي ستساعد موقع الويب الخاص بك على الظهور في الصفحة الأولى وتحقيق أداء أفضل."
                            },
                            "project31": { 
                                "title":"تصميم موقع بلدية حارة صيدا",
                                "subtitle":"منصة نيزك لتقصير الروابط هي عبارة عن نظام أساسي لتقصير الروابط يتيح لك تقصير الروابط بسهولة واستهداف جمهورك بناءً على موقعهم أو نظامهم الأساسي ، وتقديم رؤى تحليلية للروابط المختصرة."
                                },
                                "mosaweqen12": { 
                        "title": "تصميم موقع هوست نيوز",
                        "subtitle":" نيزك أناليتكس هي عبارة عن منصة تركز على الخصوصية وتحليلات الويب وتوفر تقارير مفصلة عن زوار الويب ، مثل الوقت الفعلي والنظرة العامة والسلوك والموقع الجغرافي والتكنولوجيا والأحداث والمزيد."
                        },
                        "mabi3aat12": {
                            "title":"تصميم موقع Sam Carpet",
                            "subtitle":"هي منصة أدوات وتقارير SEO شاملة ، تتيح لك إنشاء تقارير SEO سهلة الفهم والتي ستساعد موقع الويب الخاص بك على الظهور في الصفحة الأولى وتحقيق أداء أفضل."
                            },
                            "project312": { 
                                "title":"تصميم موقع لشركة صقور المملكة",
                                "subtitle":"منصة نيزك لتقصير الروابط هي عبارة عن نظام أساسي لتقصير الروابط يتيح لك تقصير الروابط بسهولة واستهداف جمهورك بناءً على موقعهم أو نظامهم الأساسي ، وتقديم رؤى تحليلية للروابط المختصرة."
                                }
                                },

            "timeline": [
                {
                    "title": "تأسيس شركة نيزك سوفت لتصميم مواقع الكترونية",
                    "content": "تأسيس شركة نيزك سوفت شركة المتخصصة في تصميم مواقع الانترنت بمزايا غير مسبوقة، البساطة والسرعة في التصفح ."
                },
                {
                    "date": "24-05-2022",
                    "title": "إطلاق منصة نيزك أناليتكس",
                    "content": "إطلاق أول منصة تابعة لشركة نيزك سوفت بهدف توفير تقارير مفصلة عن زوار الويب ، مثل الوقت الفعلي والنظرة العامة والسلوك والموقع الجغرافي والتكنولوجيا والأحداث والمزيد."
                },
                {
                    "date": "19-06-2022",
                    "title": "إطلاق منصة نيزك رانك",
                    "content": "إطلاق منصة تابعة لشركة نيزك سوفت بهدف إتاحة إنشاء تقارير SEO سهلة الفهم والتي ستساعد موقع الويب الخاص بك على الظهور في الصفحة الأولى وتحقيق أداء أفضل.."
                },
                {
                    "date": "21-08-2022",
                    "title": "إطلاق منصة نيزك لتقصير الروابط",
                    "content": "إطلاق منصة تابعة لشركة نيزك سوفت بهدف تقصير الروابط بسهولة واستهداف جمهورك بناءً على موقعهم أو نظامهم الأساسي ، وتقديم رؤى تحليلية للروابط المختصرة."
                }
                
                
            ],
            
            "contact": {
                "subheader": "أو تواصل معنا عبر الوسائل اﻵتية:",
                "email": "البريد اﻹلكتروني",
                "instagram": "انستغرام",
                "twitter": "توتير",
                "facebook": "صفحة فيسبوك",
                "website": "موقع الويب"
            }
        },
        "en": {
            "sections": {
                "1": {
                    "title": "The most important work of the web design company Nayzq Soft",
                    "subtitle": "All the services you need in one place and in safe hands"
                },
                "about": {
                    "title": "About Us",
                    "subtitle": "Who we are and What are our goals.",
                    "row1": {
                        "title": "who we are, What we do",
                        "content": "Web design company Nayzq Soft offers you services loaded with the conclusion of years of experience and hard work, we know the requirements of your business and know how to put our customers at the forefront through an integrated business plan, starting with the design of the visual identity through the design of a distinctive website and ending with the preparation of a marketing plan that ensures them increase the number of those interested in their business and increase in the proportions of their sales and profits. We at the web design company Nayzq Soft work on the design of websites according to the latest international standards to be compatible with all smart devices and friendly to different search engines."
                    },
                    "row2": {
                        "title": "Design fast websites that are compatible with mobile and smart devices",
                        "content": "All the designs and works of the web design company Nayzq Soft are compatible with all smart devices, this feature provides a great experience for your customers, helps you achieve your goals, and get distinctive results for your business. Speed and color consistency are among the most important criteria that we take into account when creating a website, mobile application, visual identity or motion graphic video for our customers."
                    }
                },
                "contact": {
                    "subtitle": "Leave us a message and we'll reply as soon as possible"
                },

                "timeline": {
                    "title": "Timeline of our history",
                    "title1": "Portfolio",
                    "subtitle": "the most important dates and events in Nayzq Soft's history"
                },
                "products": {
                    "title": "Our Products & Projects",
                    "subtitle": "Platforms, Services and Solutions that are developed and managed by Nayzq Soft"
                },
                "works": {
                    "title": "portfolio",
                    "subtitle": "Platforms, Services and Solutions that are developed and managed by Nayzq Soft"
                },
                "industries": {
                    "title": "Services",
                    "subtitle": "Start your business online and move on to another level of success and professionalism and stand out from your competitors.",
                    "cols": [
                        {
                            "headline": "Web Design",
                            "description": "Mobile compatibility and speed in browsing are the most important features of the web design service provided by Nayzq Soft, our sites are scalable continuously and characterized by standards that ensure their protection from intrusion",
                            "as": "hjkjkj"
                        },
                        {
                            "headline": "Web Hosting",
                            "description": "Web hosting is a distinctive hosting with huge potential, high stability in performance through fast servers and strong security systems that help your site to climb in search results in Google more strongly and in record time"
                        },
                        {
                            "headline": "E-Marketing",
                            "description": "E-marketing plans suitable for everyone - funded advertising campaigns Instagram - Facebook - Google, in addition to content management services and website configuration for search engines through SEO campaigns"
                        }
                    ]
                },
                "actions": {
                    "contact": "Contact Us",
                    "about": "More About Us",
                    "products": "Our Products"
                }
            },
            "projects": {
                    "mosaweqen": { 
                        "title":"Nayzq Analytics Platform",
                        "subtitle":"Nayzq Analytics is a privacy focused, web analytics software solution. It provides detailed web traffic reports, such as Realtime, Overview, Acquisitions, Behavior, Geographic, Technology, Events, and more."
                        },
                        "mabi3aat": {
                            "title":"Nayzq Rank Platform",
                            "subtitle":" Nayzq Rank is comprehensive SEO Reports and Tools Platform, that allows you to generate insightful, concise and easy to understand SEO reports that will help your webpage rank and perform better."
                            },
                            "project3": { 
                                "title":"Nayzq Short Platform",
                                "subtitle":"Nayzq Short is an advanced URL Shortener Platform, that allows you to easily shorten links, target your audience based on their location or platform, and provide analytics insights for the shortened links."
                                }
                                
                                },
                                "work": {
                    "mosaweqen": { 
                        "title":"Website Design For My Lebanon guide",
                        "subtitle":"Nayzq Analytics is a privacy focused, web analytics software solution. It provides detailed web traffic reports, such as Realtime, Overview, Acquisitions, Behavior, Geographic, Technology, Events, and more."
                        },
                        "mabi3aat": {
                            "title":"Website Design For Infinity Cars",
                            "subtitle":" Nayzq Rank is comprehensive SEO Reports and Tools Platform, that allows you to generate insightful, concise and easy to understand SEO reports that will help your webpage rank and perform better."
                            },
                            "project3": { 
                                "title":"Website Design For Donna Beauty Lounge & Spa",
                                "subtitle":"Nayzq Short is an advanced URL Shortener Platform, that allows you to easily shorten links, target your audience based on their location or platform, and provide analytics insights for the shortened links."
                                },
                                "mosaweqen1": { 
                        "title":"Website Design For ZNN News Network",
                        "subtitle":"Nayzq Analytics is a privacy focused, web analytics software solution. It provides detailed web traffic reports, such as Realtime, Overview, Acquisitions, Behavior, Geographic, Technology, Events, and more."
                        },
                        "mabi3aat1": {
                            "title":"Website Design For Forsan Altamayu",
                            "subtitle":" Nayzq Rank is comprehensive SEO Reports and Tools Platform, that allows you to generate insightful, concise and easy to understand SEO reports that will help your webpage rank and perform better."
                            },
                            "project31": { 
                                "title":"Website Design For Municipality of Haret Saida",
                                "subtitle":"Nayzq Short is an advanced URL Shortener Platform, that allows you to easily shorten links, target your audience based on their location or platform, and provide analytics insights for the shortened links."
                                },
                                "mosaweqen12": { 
                        "title":"Website Design For Hostnews",
                        "subtitle":"Nayzq Analytics is a privacy focused, web analytics software solution. It provides detailed web traffic reports, such as Realtime, Overview, Acquisitions, Behavior, Geographic, Technology, Events, and more."
                        },
                        "mabi3aat12": {
                            "title":"Website Design For Sam Carpet",
                            "subtitle":" Nayzq Rank is comprehensive SEO Reports and Tools Platform, that allows you to generate insightful, concise and easy to understand SEO reports that will help your webpage rank and perform better."
                            },
                            "project312": { 
                                "title":"Website Design For Suqur Al-Mamlaka",
                                "subtitle":"Nayzq Short is an advanced URL Shortener Platform, that allows you to easily shorten links, target your audience based on their location or platform, and provide analytics insights for the shortened links."
                                }
                                },
            "timeline": [
                {
                    "title": "Founding Nayzq Soft Web Design Company",
                    "content": "Founding Nayzq Soft is a company specialized in web design with unprecedented advantages, simplicity and speed in browsing."
                },
                {
                    "date": "24-05-2022",
                      "title": "Lauching Nayzq Analytics",
                    "content": "Launching Nayzq Soft first platform with the aim of providing detailed reports on web visitors, such as real-time, overview, behavior, geographic location, technology, events and more."
                },
                {
                    "date": "19-06-2022",
                    "title": "Launching Nayzq Rank platform",
                    "content": "Launching a platform belonging to Nayzq Soft with the aim of enabling the creation of easy-to-understand SEO reports that will help your website appear on the front page and achieve better performance."
                   },
                {
                    "date": "21-08-2022",
                    "title": "Launching Nayzq Short",
                    "content": "Launching  platform affiliated with Nayzq soft with the aim of easily shortening links and targeting your audience based on their site or platform, providing analytical insights for shortened links."
                },
                {
                    "date": "Soon",
                    "title": "Founding Nayzq Soft for Software Debbvelopment",
                    "content": "Nayzq Soft was foundedbb to develop and provide advanced software solutions that empowers e-business industry workers and take people to the digital area in the Middle East."
                }
            ],
            "contact": {
                "subheader": "or contact us via:",
                "email": "Email Address",
                "instagram": "instagram",
                "twitter":"Twitter",
                "facebook": "Facebook Page",
                "website": "Website"
            }
        }
    }
    </i18n>
    <template>
        <div  class="page page-sections">
            <section id="headerSection" data-section="home" class="header-section dark-gradient fullpage-section">
                <animated-background />
                <v-container v-if="!loading" class="fill-height pt-12" :class="{'align-center': phoneOnly}">
                    <v-slide-y-transition appear >
                    <h1 class="strong-text--text">
                        <animate-text :duration="1500" :stop-after="0" :delay="500" :text="$t('sections.1.title')" />
                        <!-- <span class="secondary--text font-weight-medium">Nayzq Soft</span> -->
                    </h1>
                    </v-slide-y-transition>
                    <v-slide-x-transition appear>
                    <h2 class="text--text mt-5">
                        <animate-text @done="showSection1Actions = true" :duration="2500" :delay="2000" :text="$t('sections.1.subtitle')"></animate-text>
                        <!-- <div>Information Technology & Electronic Business Company</div> -->
                    </h2>
                    </v-slide-x-transition>
                    <div id="fffkri" class="actions" :class="{'fade-up-off': !showSection1Actions, 'fade-up-on': showSection1Actions}">
                        <!-- <v-btn @click="navigateToLink('#productsSection')" color="strong-text" tile :large="pcOnly" depressed outlined class="me-6">{{$t('sections.actions.products')}}</v-btn> -->
                        <v-btn @click="navigateToLink('#aboutSection')" color="strong-text" tile :large="pcOnly" depressed outlined class="me-6">{{$t('sections.actions.about')}}</v-btn>
                        <v-btn  @click="navigateToLink('#contactSection')" color="secondary" tile :large="pcOnly" outlined>{{$t('sections.actions.contact')}}</v-btn>
                    </div>
                </v-container>
    
                <div class="section-scroll-icon">
                    <scroll-icon @click="scrollToSection(2)"/>
                </div>
            </section>
            <e-section id="workSection" data-section="works" background="light" :headline="$t('sections.works.title')"  light>
                <v-row class="flex-wrap justify-center">
                    <v-col v-for="(works, i) in work" :key="i" :cols="pcOnly ? 4 : 12">
                        <v-card v-ripple="works.href ? {class: 'soft-ripple'} : false" data-aos="fade-up" :data-aos-duration="800" :data-aos-delay="400*i" class="card-shadow fill-height" :href="works.href ? works.href : ''" :target="works.href ? '_blank' : ''">
                            <v-img :src="works.image" cover height="250" />
                            <div class="py-1 px-3">
                                <v-card-title class="primary--text mb-1">{{$t('work.' + works.name + '.title')}}</v-card-title>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </e-section>
            <e-section id="contactSection" data-section="contact" :headline="$t('links.contact')" :subtitle="$t('sections.contact.subtitle')" light>
                <v-row class="justify-center px-lg-12">
                    <v-col :cols="pcOnly ? 6 : 12" class="ps-lg-12">
                        <contact-form data-once :data-aos="'fade'" :data-aos-duration="800" />
                    </v-col>
                    <v-col v-if="pcOnly" cols="1" class="text-center">
                        <v-divider vertical></v-divider>
                    </v-col>
                    <v-col :cols="pcOnly ? 3 : 12">
                        <div :data-aos="'fade'" :data-aos-duration="800">
                            <v-subheader>
                                {{$t('contact.subheader')}}
                            </v-subheader>
                            <v-list two-line>
                                <v-list-item v-for="item in contactItems" :key="item.name" :target="item.href ? '_blank' : ''" :href="item.href ? item.href : (item.name === 'email' ? `mailto:${item.value}` : '')">
                                    <v-list-item-icon>
                                        <v-icon :color="item.color">{{item.icon}}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="mb-2">{{$t('contact.' + item.name)}}</v-list-item-subtitle>
                                        <v-list-item-title>{{item.value}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
    
                        </div>
                    </v-col>
                </v-row>
            </e-section>
            <!-- <e-section headline="فريق العمل" subtitle="فريق عمل Nayzq Soft في الوقت الحالي" light>
                <v-row>
                    <v-col v-for="member in teamMembers" cols="4" :key="member.name">
                    </v-col>
                </v-row>
            </e-section> -->
    
            <e-footer />
    
            <scroll-to-top />
        </div>
    </template>
    
    <script>
        
    // @ is an alias to /src
    import 'particles.js';
    import AOS from 'aos';
    import 'aos/dist/aos.css'; // You can also use <link> for styles
    
    
    import ScrollToTop from '@/components/custom/scroll-to-top'
    import AnimateText from '@/components/custom/animate-text'
    import ScrollIcon from '@/components/custom/scroll-icon'
    import ContactForm from '@/components/custom/contact-form'
    import AnimatedBackground from '@/components/custom/animated-background'
    import ESection from '@/components/default/e-section'
    import EFooter from '@/components/main/e-footer'
    
    
    import GlobalComputed from '@/helpers/global-computed'
    import GlobalMethods from '@/helpers/global-methods'
    
    export default {
        name: "Home",
        components: {
            ScrollToTop,
            ContactForm,
            AnimateText,
            ESection,
            ScrollIcon,
            EFooter,
            AnimatedBackground
        },
        data(){
            return {
                showSection1Actions: false,
                currentScrollTop: 0,
                
                contactItems: [
                    {name: 'email', icon: 'mdi-email', value: 'info@nayzq.net', color: 'primary'},
                    {name: 'facebook', icon: 'mdi-facebook', value: '@Nayzq Soft', color: 'blue darken-1', href: 'https://facebook.com/nayzqsoft'},
                    {name: 'instagram', icon: 'mdi-instagram', value: '@Nayzq Soft', color: '#E1306C', href: 'https://instagram.com/nayzqsoft'},
                    {name: 'twitter', icon: 'mdi-twitter', value: '@Nayzq Soft', color: 'blue', href: 'https://twitter.com/nayzqsoft'},
                    {name: 'website', icon: 'mdi-web', value: 'www.nayzq.net', color: 'grey', href: 'https://nayzq.net'},
                ],
             
                        work: [
                            { image: "/img/lb.png", name: "mosaweqen", href: "https://www.mylebanonguide.com/" },
                            { image: "/img/indi-1024x683-2.png.webp", name: "mabi3aat", href: "#" },
                            { image: "/img/donna-compressed-1024x683.jpg.webp", name: "project3", href: "https://donna-spa.com" },
                            { image: "/img/znn.jpg.webp", name: "mosaweqen1", href: "https://www.znn-lb.com/" },
                            { image: "/img/forsanalt-scaled.jpg.webp", name: "mabi3aat1", href: "#" },
                            { image: "/img/haret-1-1.png.webp", name: "project31", href: "#" },
                            { image: "/img/host.png", name: "mosaweqen12", href: "https://www.znn-lb.com/" },
                            { image: "/img/sam.png", name: "mabi3aat12", href: "https://sam-carpet.com/" },
                            { image: "/img/suqur.png", name: "project312", href: "#" },
                       
                        ],
                teamMembers: [
                    {name: 'Hussein Saleh', job: 'Founder, CEO & Developer', avatar: '/img/team/hs.jpg', description: 'Founder and CEO of Nayzq Soft company '}
                ],
    
                section2Columns: [
                    {
                        headline: 'العنوان اﻷول',
                        description: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها',
                        color: 'success',
                        icon: 'mdi-cart'
                    },
                    {
                        headline: 'العنوان الثاني',
                        description: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها',
                        color: 'blue',
                        icon: 'mdi-cube-send'
                    },
                    {
                        headline: 'العنوان الثالث',
                        description: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها',
                        color: 'deep-orange',
                        icon: 'mdi-cash-multiple'
                    }
                ]
            }
        },
        computed: {
            industriesCols(){
                return [
                   {color: 'blue', icon: 'mdi-web', headline: this.$t('sections.industries.cols.0.headline'), description: this.$t('sections.industries.cols.0.description'),  as: this.$t('sections.industries.cols.0.as')},
                   {color: 'deep-orange', icon: 'mdi-database', headline: this.$t('sections.industries.cols.1.headline'), description: this.$t('sections.industries.cols.1.description')},
                   {color: 'teal', icon: 'mdi-target', headline: this.$t('sections.industries.cols.2.headline'), description: this.$t('sections.industries.cols.2.description')},
                ];
            },
            timeline(){
    
                return [
                    {date: '27-04-2022', title: this.$t('timeline.0.title'), content: this.$t('timeline.0.content')},
                    {date: this.$t('timeline.1.date'), title: this.$t('timeline.1.title'), content: this.$t('timeline.1.content')},
                    {date: this.$t('timeline.2.date'), title: this.$t('timeline.2.title'), content: this.$t('timeline.2.content')},
                    {date: this.$t('timeline.3.date'), title: this.$t('timeline.3.title'), content: this.$t('timeline.3.content')}
                ];
            },
            ...GlobalComputed
        },
        methods: {
            scrollToSection(n){
                let i = n-1,
                    element = document.querySelectorAll('.page-sections section')[i];
                if (element){
                    this.scrollToElement(element);
                }
            },
            scrollObserver(){
                window.addEventListener('scroll', ()=>{
                    this.currentScrollTop = document.documentElement.scrollTop;
                })
            },
            handleScrollChange(scrollTop){
                const sections = document.querySelectorAll('.page-sections section');
                sections.forEach(section => {
                    let offsetTop = section.offsetTop - this.navbarHeight, // decrease navbarHeight
                        offsetBottom = offsetTop + section.offsetHeight;
    
                    if (scrollTop >= offsetTop && scrollTop < offsetBottom){
                        let sectionName = section.getAttribute('data-section');
                        if(sectionName){
                            this.$store.commit('SET_ACTIVE_SECTION', sectionName)
                        }
                        // section is visible...
                        if (section.getAttribute('data-theme') === 'light'){
                            
                            if (!this.lightNavbar){
                                this.$store.commit('SET_LIGHT_NAVBAR', true);
                            }
                        }else{
                            if (this.lightNavbar){
                                this.$store.commit('SET_LIGHT_NAVBAR', false);
                            }
                        }
                    }
                })
    
            },
    
            ...GlobalMethods
        },
        watch: {
            currentScrollTop(top){
                this.handleScrollChange(top);
            }
        },
    
        mounted(){
            this.scrollObserver();
            AOS.init({container: document.documentElement, once: true, easing: 'ease'});
        }
    };

    
    </script>
    <style lang="scss" scoped>
        section{
    
            .container{
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
    
                &, *{
                    z-index: 1;
                }
            }
            &.header-section{
                color: white;
            }
            h1{
                font-size: 4rem;
                font-weight: 300;
    
                @media(max-width: 960px){
                    text-align: center;
                    font-size: 1.7rem;
                    line-height: 2.4rem;
                    font-weight: 400;
                }
            }
            h2{
                font-size: 2.75rem;
                word-break: break-word!important;
                font-weight: 200;
    
                @media(max-width: 960px){
                    text-align: center;
                    font-size: 1.3rem;
                    font-weight: 300;
                }
            }
            #jssdd {
    width: 75%;
}
            .actions{
                margin-top: 3rem;
                @media(max-width: 960px){
                    margin: 2rem 0;
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                }
                .v-btn{
                    @media(min-width: 960px){
                        min-width: 130px;
                        min-height: 45px;
                    }
                }
            }
            .section-scroll-icon{
                z-index: 2;
                position: absolute;
                bottom: 8%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    </style>

    