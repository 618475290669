<i18n>
{
    "ar": {
        "contact": {

            "name": "الاسم",
            "email": "البريد اﻹلكتروني",
            "title": "عنوان الرسالة",
            "content": "محتوى الرسالة",
            "send": "إرسال الرسالة",
            "placeholders": {
                "name": "أدخل اسمك (اسم المرسل)",
                "email": "أدخل بريدك اﻹلكتروني",
                "title": "أدخل عنوان الرسالة",
                "content": "أدخل محتوى الرسالة"
            }
        }
    },
    "en": {
        "contact": {

            "email": "Email Address",
            "name": "Name",
            "title": "Message Subject",
            "content": "Message Content",
            "send": "Send Message",
            "placeholders": {
                "name": "Enter Your Name",
                "email": "Enter Your Email Address",
                "title": "Enter Message Subject",
                "content": "Enter Message Content"
            }
        }
    }
}
</i18n>
<template>
    
<form ref="form" @submit.prevent="sendEmail">
    <v-row>
        <v-col :cols="pcOnly ? 6 : 12">
            <v-text-field  hide-details outlined type="text" name="user_name"  id="username"  required="" :label="$t('contact.name')" :placeholder="$t('contact.placeholders.name')" />
        </v-col>
        <v-col :cols="pcOnly ? 6 : 12">
            <v-text-field hide-details outlined type="email" class="form-control" name="user_email"   id="useremail"  :label="$t('contact.email')" :placeholder="$t('contact.placeholders.email')" />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-text-field hide-details outlined name="subject" id="subject"  :label="$t('contact.title')" :placeholder="$t('contact.placeholders.title')" />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-textarea rows="4" hide-details no-resize auto-grow outlined id="message" name="message" :label="$t('contact.content')" :placeholder="$t('contact.placeholders.content')" />
        </v-col>
    </v-row>
    <div class="contact-actions d-flex mt-4">
        <v-btn block x-large tile  @click="resetForm()" type="submit" value="Send" color="primary">
            <span>{{$t('contact.send')}}</span>
            <v-icon class="ms-3">mdi-arrow-{{isRtl ? 'left' : 'right'}}</v-icon>
        </v-btn>
    </div>
</form>
</template>
<script>
    import emailjs from '@emailjs/browser';
    
    export default {
      methods: {
        sendEmail() {
          emailjs.sendForm('service_rxf151g', 'template_q47xi97', this.$refs.form, 'dnG2a2TPL1wm6J424')
            .then((result) => {
                console.log('<h1 class="gfa">SUCCESS!</h1>', result.text);
                alert("Email sent successfully!");
                this.resetForm(); //clear form automatically after successful request
            }, (error) => {
                console.log('FAILED...', error.text);
                alert("FAILED!"+error);
            });
            document.getElementById('username').value = '';
            document.getElementById('useremail').value = '';
            document.getElementById('subject').value = '';
            document.getElementById('message').value = '';
        }
      }
    }
    
    </script>

<style lang="scss" scoped>

</style>
