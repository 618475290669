<i18n>
{
    "ar": {
        "linksa": {
        "home": "معرض أعمالنا",
        "about": "عن الشركة",
        "products": "مشاريعنا",
        "timeline": "الشريط الزمني",
        "contact": "تواصل معنا",
        "about": "من نحن"
    }
        
    },
    "en": {
        "linksa": {
        "home": "portfolio",
        "about": "عن الشركة",
        "products": "مشاريعنا",
        "timeline": "الشريط الزمني",
        "contact": "تواصل معنا",
        "about": "من نحن"
    }
        
    }
}
</i18n>
<template>
    <v-app-bar :light="light" :height="scrolled ? this.navbarHeight : (pcOnly ? 130 : 100)" class="navbar" :class="{'blank': !scrolled & !light, 'soft-shadow': light, 'dark-gradient': scrolled && !light}" :flat="!light" fixed :color="light ? 'white' : 'dark'" :dark="!light">
        <v-container class="d-flex align-center">
            <div>
                <!-- logo -->
                <logo :light="light" :width="pcOnly ? 140 : 125" />
            </div>
            <v-spacer />
            <v-slide-x-reverse-transition appear>
                <div class="d-flex">
                    <div v-if="pcOnly" class="d-flex align-center">
                        <v-btn :color="activeSection === link.name ? 'secondary' : undefined" :outlined="activeSection === link.name" v-for="link in links" :key="link.name" large tile :text="activeSection !== link.name" class="ms-2" @click="navigateToLink(link.ref)">{{$t(`links.${link.name}`)}}</v-btn>
                        <v-btn id="sjdj" to="/portfolio" large tile  style="background:none" box-shadow="none" class="ms-2">{{$t(`linksa.home`)}}</v-btn>
                    </div>
                    <div class="d-flex align-center ms-8">
                        <locale-button :large="pcOnly" />
                        

                    </div>

                </div>
                
            </v-slide-x-reverse-transition>
            
        </v-container>
    </v-app-bar>
</template>
<script src="js/mobile-menu.js"></script>
<script>
import LocaleButton from '../custom/locale-button'
import Logo from './logo'


import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
import links from '@/helpers/links'


export default {
    name: 'navbar',
    components: {Logo, LocaleButton},
    props: {
        lightTheme: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            scrolled: false,

            links,
        }
    },
    computed: {
        light(){
            return this.lightTheme && this.scrolled
        },
        activeSection(){
            return this.$store.state.activeSection;
        },
        ...GlobalComputed
    },
    methods: {
        handleScroll(){
            this.scrolled = window.scrollY > 0;
        },

        ...GlobalMethods
    },
    mounted(){
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }
}
</script>

<style lang="scss">
    .v-application .navbar{
        &, .v-toolbar__content{
            transition: height 0.2s ease-out, background-color 0.1s linear!important;
        }
        &.soft-shadow{
            box-shadow: 0 0 transparent, 0 0 transparent, 0 5px 5px -4px rgba(0, 0, 0, 0.10)!important;
        }
        &.dark-gradient{
            box-shadow: 0 1px 8px 0px rgba(0,0,0, .2)!important;
        }
        &.blank{
            &, .v-toolbar__content{
                background: none!important;
                border: none!important;
                box-shadow: none!important;
            }
        }
        .v-btn{
            font-weight: normal!important;
            text-transform: capitalize;
            letter-spacing: 1.1px;

        }
    }
    a#sjdj {
    box-shadow: none;
}






.window .header {
	 position: absolute;
	 display: block;
	 top: 0;
	 left: 0;
	 height: 50px;
	 width: 100%;
	 background: rgba(0, 0, 0, 0.8);
	 overflow: hidden;
	 transition: all 0.5s ease-out, background 1s ease-out;
	 transition-delay: 0.2s;
	 z-index: 1;
}
 .window .header .burger-container {
	 position: relative;
	 display: inline-block;
	 height: 50px;
	 width: 50px;
	 cursor: pointer;
	 transform: rotate(0deg);
	 transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	 user-select: none;
	 -webkit-tap-highlight-color: transparent;
}
 .window .header .burger-container #burger {
	 width: 18px;
	 height: 8px;
	 position: relative;
	 display: block;
	 margin: -4px auto 0;
	 top: 50%;
}
 .window .header .burger-container #burger .bar {
	 width: 100%;
	 height: 1px;
	 display: block;
	 position: relative;
	 background: #fff;
	 transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	 transition-delay: 0s;
}
 .window .header .burger-container #burger .bar.topBar {
	 transform: translateY(0px) rotate(0deg);
}
 .window .header .burger-container #burger .bar.btmBar {
	 transform: translateY(6px) rotate(0deg);
}
 .window .header .icon {
	 display: inline-block;
	 position: absolute;
	 height: 100%;
	 line-height: 50px;
	 width: 50px;
	 height: 50px;
	 text-align: center;
	 color: #fff;
	 font-size: 22px;
	 left: 50%;
	 transform: translateX(-50%);
}
 .window .header .icon.icon-bag {
	 right: 0;
	 top: 0;
	 left: auto;
	 transform: translateX(0px);
	 transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	 transition-delay: 0.65s;
}
 .window .header ul.menu {
	 position: relative;
	 display: block;
	 padding: 0px 48px 0;
	 list-style: none;
}
 .window .header ul.menu li.menu-item {
	 border-bottom: 1px solid #333;
	 margin-top: 5px;
	 transform: scale(1.15) translateY(-30px);
	 opacity: 0;
	 transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
 .window .header ul.menu li.menu-item:nth-child(1) {
	 transition-delay: 0.49s;
}
 .window .header ul.menu li.menu-item:nth-child(2) {
	 transition-delay: 0.42s;
}
 .window .header ul.menu li.menu-item:nth-child(3) {
	 transition-delay: 0.35s;
}
 .window .header ul.menu li.menu-item:nth-child(4) {
	 transition-delay: 0.28s;
}
 .window .header ul.menu li.menu-item:nth-child(5) {
	 transition-delay: 0.21s;
}
 .window .header ul.menu li.menu-item:nth-child(6) {
	 transition-delay: 0.14s;
}
 .window .header ul.menu li.menu-item:nth-child(7) {
	 transition-delay: 0.07s;
}
 .window .header ul.menu li.menu-item a {
	 display: block;
	 position: relative;
	 color: #fff;
	 font-family: 'Ek Mukta', sans-serif;
	 font-weight: 100;
	 text-decoration: none;
	 font-size: 22px;
	 line-height: 2.35;
	 font-weight: 200;
	 width: 100%;
}
 .window .header.menu-opened {
	 height: 100%;
	 background-color: #000;
	 transition: all 0.3s ease-in, background 0.5s ease-in;
	 transition-delay: 0.25s;
}
 .window .header.menu-opened .burger-container {
	 transform: rotate(90deg);
}
 .window .header.menu-opened .burger-container #burger .bar {
	 transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	 transition-delay: 0.2s;
}
 .window .header.menu-opened .burger-container #burger .bar.topBar {
	 transform: translateY(4px) rotate(45deg);
}
 .window .header.menu-opened .burger-container #burger .bar.btmBar {
	 transform: translateY(3px) rotate(-45deg);
}
 .window .header.menu-opened ul.menu li.menu-item {
	 transform: scale(1) translateY(0px);
	 opacity: 1;
}
 .window .header.menu-opened ul.menu li.menu-item:nth-child(1) {
	 transition-delay: 0.27s;
}
 .window .header.menu-opened ul.menu li.menu-item:nth-child(2) {
	 transition-delay: 0.34s;
}
 .window .header.menu-opened ul.menu li.menu-item:nth-child(3) {
	 transition-delay: 0.41s;
}
 .window .header.menu-opened ul.menu li.menu-item:nth-child(4) {
	 transition-delay: 0.48s;
}
 .window .header.menu-opened ul.menu li.menu-item:nth-child(5) {
	 transition-delay: 0.55s;
}
 .window .header.menu-opened ul.menu li.menu-item:nth-child(6) {
	 transition-delay: 0.62s;
}
 .window .header.menu-opened ul.menu li.menu-item:nth-child(7) {
	 transition-delay: 0.69s;
}
 .window .header.menu-opened .icon.icon-bag {
	 transform: translateX(75px);
	 transition-delay: 0.3s;
}
 .window .content {
	 font-family: 'Ek Mukta', sans-serif;
	 padding: 67px 4% 0;
	 text-align: justify;
	 overflow: scroll;
	 max-height: 100%;
}
 .window .content::-webkit-scrollbar {
	 display: none;
}
 .window .content h2 {
	 margin-bottom: 0px;
	 letter-spacing: 1px;
}
 .window .content img {
	 width: 95%;
	 position: relative;
	 display: block;
	 margin: 75px auto 75px;
}
 .window .content img:nth-of-type(2) {
	 margin: 75px auto;
}
 @media (max-width: 600px) {
	 .window {
		 width: 100%;
		 height: 100vh;
		 margin: 0;
		 border-radius: 0px;
	}
	 .window .header {
		 position: fixed;
	}
}
  
</style>
